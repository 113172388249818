@import "~aos/dist/aos.css";
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.eot');
    src: url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BoldItalic.eot');
    src: url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff'),
        url('../fonts/Roboto-BoldItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic.eot');
    src: url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff'),
        url('../fonts/Roboto-Italic.ttf') format('truetype'),
        url('../fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BlackItalic.eot');
    src: url('../fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff'),
        url('../fonts/Roboto-BlackItalic.ttf') format('truetype'),
        url('../fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-LightItalic.eot');
    src: url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff'),
        url('../fonts/Roboto-LightItalic.ttf') format('truetype'),
        url('../fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-MediumItalic.eot');
    src: url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff'),
        url('../fonts/Roboto-MediumItalic.ttf') format('truetype'),
        url('../fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Thin.eot');
    src: url('../fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff'),
        url('../fonts/Roboto-Thin.ttf') format('truetype'),
        url('../fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-ThinItalic.eot');
    src: url('../fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff'),
        url('../fonts/Roboto-ThinItalic.ttf') format('truetype'),
        url('../fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

$f_primary: 'Roboto';
body{
  font-family: $f_primary !important;
}
.header{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px 110px;
  z-index: 99999;
  background: linear-gradient(180deg, #000000 0%, #212121 100%);
  box-shadow: 0px 0px 30px rgba(31, 199, 212, 0.5);
  .desc_linl_pnt{
    margin: 0 auto;
  }
  .header_inn{
    align-items: center;
  }
  .link_prnt{
    display: flex;
    align-items: center;
    a{
      font-family: $f_primary;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      background: transparent !important;
      box-shadow: none !important;
      margin-right:55px;
      transition: all 0.2s ease-out;
      cursor: pointer;
      &:hover, &.active{
        color: #53e8f8;
      }
    }
    .last_link{
      margin-right: 0;
    }
  }
  .enterapp_prnt{
    .enterapp_btn{
      width: 147px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1CE8F8;
      border-radius: 60px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #353535;
      // border: 1px solid #0D6EFD;
      transition: all 0.2s ease-out;
      &:hover{
        background: #05b9c7;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .header {
    padding: 10px 60px;
  }
  .header .link_prnt a {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .header {
    padding: 10px 30px;
  }
  .header .link_prnt a {
    margin-right: 15px;
    font-size: 13px;
  }
}
@media screen and (min-width: 992px) {
  .mobl_link_prnt{
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .link_prnt{
    flex-direction: column;
    padding: 5px;
    a{
      margin-top: 15px;
      margin-right: 0 !important;
    }
    .last_link{
      margin-bottom: 15px;
    }
  }
  .header .nav_callpse{
    position: absolute !important;
    left: 0;
    top: 72px !important;
    background: #272d37d3;
    width: 100% !important;
  }
  .header .collpse_btn {
    border: 2px solid #1ce8f8 !important;
    box-shadow: none !important;
    background: transparent !important;
    svg{
      color: #1ce8f8;
    }
  }
}
@media screen and (max-width: 599px) {
  .header{
    padding: 10px 20px;
    .collpse_btn_prnt{
      flex: none !important;
    }
    .main_logo{
      margin: 0 13px;
      height: 40px;
    }
    .main_logo_prnt{
      justify-content: flex-start !important;
    }
  }
  .header .enterapp_prnt .enterapp_btn {
    width: 110px;
    height: 34px;
    font-size: 13px;
  }
  .header .nav_callpse {
    top: 60px !important;
  }
  .link_prnt a {
    margin-top: 10px;
  }
  .link_prnt .last_link {
    margin-bottom: 10px;
  }
}
.homepage_main {
  background: url(../img/homepage_bg.jpeg) no-repeat center left;
  min-height: 5148px;
  background-size: cover;
}
.upcro_launch_main{
  display: flex;
  max-width: 1390px;
  margin: 0 auto;
  width: 100%;
  padding-top: 140px;
  padding-right: 15px;
  padding-left: 15px;
  .launch_text_box{
    min-height: 693px;
    display: flex;
    align-items: center;
    width: 100%;
    .launch_text_box_inn{
      max-width: 640px;
      margin-left: auto;
      padding-right: 7px;
      h3{
        font-family: $f_primary;
        font-weight: 600;
        font-size: 46px;
        line-height: 54px;
        color: #FFFFFF;
      }
      p{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
        color: #FFFFFF;
        margin-top: 32px;
        margin-bottom: 55px;
      }
      .launch_btns{
        display: flex;
        align-items: center;
        a{
          background: #1CE8F8;
          border-radius: 60px;
          width: 200px;
          height: 42px;
          font-family: $f_primary;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #353535;
          &:hover{
            background: #05b9c7;
            color: #FFFFFF
          }
        }
        .entr_a{
          margin: 0 15px;
        }
      }
    }
  }
  .upcro_first_img{
    max-width: 806px;
    position: absolute;
    right: 7%;
    top: 3%;
    width: 100%;
    margin: 0 auto;
    mix-blend-mode: lighten;
  }
}
.width_full{
  width: 100% !important; 
}
@media screen and (max-width:1688px) {
  .upcro_launch_main .upcro_first_img{
    max-width: 721px;
    top: 3%;
    left: 48%;
  }
  .upcro_first_img > div {
    width: 550px !important;
    height: 550px !important;
  }
} 
@media screen and (max-width: 1400px) {
  .upcro_launch_main .launch_text_box .launch_text_box_inn .launch_btns a {
    width: 150px;
    height: 36px;
    font-size: 13px;
    line-height: 16px;
  }
  .upcro_launch_main .upcro_first_img {
    top: 5%;
  }
  .upcro_launch_main .upcro_first_img{
    max-width: 540px;
  }
  .upcro_first_img > div {
    width: 450px !important;
    height: 450px !important;
  }
  .upcro_launch_main .launch_text_box .launch_text_box_inn p {
    font-size: 21px;
    line-height: 32px;
    margin-top: 25px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width:1199px) {
  .upcro_launch_main .upcro_first_img{
    max-width: 522px;
    top: 5%;
    left: 47%;
  }
}
@media screen and (max-width: 991px) {
  .upcro_launch_main .launch_text_box .launch_text_box_inn {
    margin-left: 0;
    padding-right: 30px;
    padding-left: 30px;
  }
  .upcro_launch_main .launch_text_box{
    min-height: 0;
    margin-bottom: 30px;
  }
  .upcro_launch_main{
    flex-direction: column;
  }
  .upcro_launch_main .upcro_first_img{
    position: relative;
    left: 0;
  }
  .aboutus_main {
    margin-top: 15px !important;
  }
}
@media screen and (max-width: 575px) {
  .upcro_launch_main .launch_text_box .launch_text_box_inn .launch_btns{
    flex-direction: column;
    align-items: flex-start;
    .entr_a {
      margin: 10px 0 10px 0;
    }
  }
  .upcro_launch_main .launch_text_box .launch_text_box_inn h3 {
    font-size: 30px;
    line-height: 50px;
  }
  .upcro_launch_main .launch_text_box .launch_text_box_inn p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 15px;
    margin-bottom: 28px;
  }
  .upcro_launch_main {
    padding-top: 100px;
    
  }
  .upcro_launch_main .launch_text_box .launch_text_box_inn{
    padding: 0;
  }
  .aboutus_main {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 490px) {
  .upcro_first_img>div{
    width: 290px !important;
    height: 290px !important;
  }
}

.aboutus_main{
  margin-top: 90px;
  .about_border_box{
    position: relative;
    min-height: 200px;
    border-radius: 90px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      border: 1px solid transparent;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1), #1CE8F8 ) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      border-radius: 90px;
      pointer-events: none;
    }
    .sadow_box{
      padding: 30px 0 35px 0;
      background: #000000;
      box-shadow: 0px 15px 80px rgba(31, 199, 212, 0.2);
      border-radius: 90px;
      h6{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #1CE8F8;
      }
      h3{
        font-family: $f_primary;
        font-weight: 600;
        font-size: 42px;
        margin-top: 14px;
        line-height: 49px;
        text-align: center;
        color: #FFFFFF;
      }
    }
    .about_text_prnt{
      display: flex;
      justify-content: center;
      max-width: 1110px;
      padding: 50px 15px 85px 15px;
      margin: 0 auto;
      p{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        span{
          color: #1CE8F8;
        }
      }
    }
    .upcro_ic{
      position: absolute;
      bottom: -35px;
      left: calc(50% - 35px);
      filter: drop-shadow(0px 15px 80px rgba(13, 110, 253, 0.6)) !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .aboutus_main .about_border_box .sadow_box {
    padding: 25px 0 31px 0;
    h6{
      font-size: 19px;
      line-height: 24px;
    }
    h3{
      font-size: 38px;
      margin-top: 12px;
      line-height: 46px;
    }
  }
}
@media screen and (max-width: 767px) {
  .aboutus_main .about_border_box .sadow_box {
    padding: 22px 0 28px 0;
    h6{
      font-size: 19px;
      line-height: 24px;
    }
    h3{
      font-size: 34px;
      margin-top: 10px;
      line-height: 40px;
    }
  }
  .aboutus_main .about_border_box .about_text_prnt {
    padding: 46px 15px 66px 15px;
    p{
      font-size: 17px;
      line-height: 26px;
    }
  }
}
@media screen and (max-width: 575px) {
  .aboutus_main .about_border_box .sadow_box {
    h6{
      font-size: 17px;
      line-height: 22px;
    }
    h3{
      font-size: 27px;
      margin-top: 12px;
      line-height: 32px;  
    }
  }
  .aboutus_main .about_border_box .about_text_prnt {
    padding: 40px 15px 60px 15px;
    p{
      font-size: 15px;
      line-height: 22px;
    }
  }
  .aboutus_main {
    margin-top: 30px;
  }
}
.technology_main{
  margin-top: 198px;
  padding: 0 15px;
  .tech_bg_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 759px;
    padding: 0 20px;
    max-width: 1744px;
    width: 100%;
    background: #010204 url(../img/tech_bg_img.png) no-repeat center 0;
    background-size: cover;
    border-radius: 90px;
    margin: 0 auto;
    h6{
      font-family: $f_primary;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #1CE8F8;
      margin-top: 45px;
    }
    h3{
      font-family: $f_primary;
      font-weight: 600;
      font-size: 44px;
      line-height: 52px;
      text-align: center;
      color: #FFFFFF;
      margin-top: 15px;
      margin-bottom: 25px;
    }
    p{
      font-family: $f_primary;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      max-width: 1026px;
      margin-bottom: 48px;
      span{
        color: #1CE8F8;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .techno_img_prnt{
      margin-top: auto;
      margin-bottom: -15px;
      // img{
      //   mix-blend-mode: multiply;
      // }
    }
    .darkbg_btn_bx{
      background: #000000;
      box-shadow: 0px -2px 30px rgba(149, 191, 254, 0.2) !important;
      border-radius: 60px 60px 0px 0px;
      margin-top: auto;
      max-width: 493px;
      min-height: 88px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      a{
        width: 206px;
        height: 42px;
        background: #1CE8F8;
        border-radius: 60px;
        font-family: $f_primary;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #353535;
        box-shadow: none !important;
        transition: all 0.2s ease-out;
        &:hover{
          background: #05b9c7;
          color: #FFFFFF;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .technology_main{
    margin-top: 100px;
  }
  .technology_main .tech_bg_box .darkbg_btn_bx {
    max-width: 378px;
  }
}
@media screen and (max-width: 575px) {
  .technology_main{
    margin-top: 80px;
  }
  .technology_main .tech_bg_box h6 {
    font-size: 18px;
    line-height: 20px;
    margin-top: 35px;
  }
  .technology_main .tech_bg_box h3 {
    font-size: 30px;
    line-height: 36px;
    margin-top: 12px;
    margin-bottom: 20px;
  }
  .technology_main .tech_bg_box p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 25px;
  }
  .technology_main .tech_bg_box {
    padding: 0 10px;
  }
  .technology_main .tech_bg_box .darkbg_btn_bx a {
    width: 150px;
    height: 38px;
    font-size: 13px;
    line-height: 16px;
  }
  .technology_main .tech_bg_box {
    border-radius: 35px;
    min-height: 0;
  }
  .technology_main .tech_bg_box .darkbg_btn_bx{
    margin-top: 0;
  }
  .technology_main .tech_bg_box .darkbg_btn_bx {
    max-width: 210px;
    min-height: 76px;
  }
  .technology_main .tech_bg_box .techno_img_prnt{
    margin-top: 0;
    margin-bottom: -5px;
  }
}
.feature_main{
  .featur_max_box{
    max-width: 1180px;
    margin: 165px auto 0 auto;
    .feature_text_box{
      padding-top: 30px;
      h6{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #1CE8F8;
      }
      h3{
        font-family: $f_primary;
        font-weight: 600;
        font-size: 44px;
        line-height: 52px;
        color: #FFFFFF;
        margin-top: 13px;
        margin-bottom: 17px;
      }
      p{
        font-family: $f_primary;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        max-width: 547px;
        b{
          color: #1CE8F8;
        }
      }
    }
  }
}
.feature_img_prnt{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1279px) {
  .feature_main .featur_max_box .feature_text_box h3 {
    font-size: 35px;
    line-height: 46px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 991px) {
  .feature_main .featur_max_box {
    margin: 100px auto 0 auto;
  }
  .feature_img_prnt {
    margin-top: 40px;
  }
}
@media screen and (max-width: 575px) {
  .feature_main .featur_max_box {
    margin: 50px auto 0 auto;
  }
  .feature_main .featur_max_box .feature_text_box h6 {
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
  }
  .feature_main .featur_max_box .feature_text_box h3 {
    font-size: 29px;
    line-height: 36px;
  }
  .feature_main .featur_max_box .feature_text_box p {
    font-size: 15px;
    line-height: 22px;
  }
  .feature_img_prnt {
    margin-top: 26px;
  }
}
.team_main{
  margin-top: 220px;
  .team_grid_prnt{
    padding: 0 20px;
  }
  .about_border_box{
    position: relative;
    min-height: 200px;
    border-radius: 90px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      border: 1px solid transparent;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1), #05b9c7) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      border-radius: 90px;
      pointer-events: none;
    }
    .sadow_box{
      padding: 30px 0 35px 0;
      background: #000000;
      box-shadow: 0px 15px 80px rgba(31, 199, 212, 0.2);
      border-radius: 90px;
      h6{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #1CE8F8;
      }
      h3{
        font-family: $f_primary;
        font-weight: 600;
        font-size: 42px;
        margin-top: 14px;
        line-height: 49px;
        text-align: center;
        color: #FFFFFF;
      }
    }
    .enter_app_btn_prnt{
      display: flex;
      width: 100%;
      margin-top: 18px;
      .enter_app_btn{
        background: #1CE8F8;
        border-radius: 60px;
        width: 206px;
        height: 42px;
        font-family: $f_primary;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #353535;
        margin: 0 auto;
        bottom: -21px;
        position: relative;
        box-shadow:  none !important;
        transition: all 0.2s ease-out;
        &:hover{
          background: #05b9c7;
          color: #FFFFFF;
        }
      }
    }
  }
  .team_p_prnt{
    margin-top: 55px;
    display: flex;
    align-items: center;
    min-height: 336px;
    justify-content: center;
    p{
      margin-left: auto;
      font-family: $f_primary;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #FFFFFF;
      max-width: 460px;
      b{
        color: #1CE8F8;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .team_main {
    margin-top: 120px;
  }
  .team_main .about_border_box .sadow_box h3 {
    font-size: 38px;
    margin-top: 10px;
    line-height: 46px;
  }
  .team_main .about_border_box .sadow_box{
    padding: 26px 20px 31px 20px;
  }
  .team_main .team_p_prnt{
    min-height: 0;
  }
  .team_main .team_p_prnt p {
    margin-right: auto;
  }
  .team_main .team_img_prnt{
    margin-top: 30px;
    img{
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 767px) {
  .team_main .about_border_box .sadow_box h3 {
    font-size: 33px;
    margin-top: 10px;
    line-height: 42px;
  }
  .team_main {
    margin-top: 80px;
  }
}
@media screen and (max-width: 575px) {
  .team_main .about_border_box .sadow_box h6 {
    font-size: 18px;
    line-height: 22px;
  }
  .team_main .about_border_box .sadow_box h3 {
    font-size: 28px;
    margin-top: 13px;
    line-height: 32px;
  }
  .team_main .team_p_prnt {
    margin-top: 41px;
  }
  .team_main .team_img_prnt{
    margin-top: 20px;
  }
  .team_main .team_p_prnt p {
    font-size: 15px;
    line-height: 20px;
  }
  .team_main .team_grid_prnt {
    padding: 0 15px;
  }
  .team_main .about_border_box .enter_app_btn_prnt .enter_app_btn {
    width: 150px;
    height: 36px;
    font-size: 13px;
    line-height: 16px;
    bottom: -18px;
  }
}
.articlesec_main{
  margin-top: 140px;
  h6{
    font-family: $f_primary;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #1CE8F8; 
  }
  h3{
    font-family: $f_primary;
    font-weight: 600;
    font-size: 44px;
    line-height: 52px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 13px;
    margin-bottom: 40px;
  }
  .artcl_slider_cntnt{
    padding:0 20px 57px 20px;
  }
  .artcl_slider_inn{
    background: #000000;
    box-shadow: 0px 14px 23px rgba(31, 199, 212, 0.2) !important;
    border-radius: 16px;
    max-width: 400px;
    margin: 0 auto;
    .slider_text_box{
      padding: 20px 20px 35px 20px;
      display: flex;
      flex-direction: column;
      h4{
        font-family: $f_primary;
        font-weight: 600;
        font-size: 21px;
        line-height: 34px;
        color: #FFFFFF;
      }
      .flex_box{
        display: flex;
        align-items: center;
        margin-top: 28px;
        p{
          display: flex;
          align-items: center;
          margin-right: 10px;
          font-family: $f_primary;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: #FFFFFF;
        }
        a{
          display: flex;
          align-items: center;
          margin-left: auto;
          font-family: $f_primary;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #1CE8F8;
          background: transparent;
          padding: 0 !important;
          box-shadow: none !important;
          height: auto;
          img{
            margin-left: 5px;
          }
          &:hover{
            img{
              filter: invert(54%) sepia(93%) saturate(5742%) hue-rotate(207deg) brightness(96%) contrast(107%);
            }
            color: #0D6EFD;
          }
        }
      }
    }
  }
  .slick-dots{
    bottom: -18px;
    li{
      button{
      background-color: #075960 !important;
      width: 14px;
      height: 14px;
      border-radius: 50% !important;
      margin: 0 auto;
      transition: all 0.2s ease-out !important;
        &::before{
          display: none !important;
        }
        &:hover{
          width: 20px;
          height: 20px;
        }
      }
    }
    .slick-active{
      button{
        width: 20px;
        height: 20px;
        background: #1CE8F8 !important;
          &::before{
            display: none !important;
          }
        }
    }
  }
  .slick-prev{
    top: auto !important;
    bottom: 0 !important;
    bottom: -25px !important;
    height: 20px !important;
    width: 83px !important;
    background: url(../img/prew_arrow.svg) no-repeat 0 0 !important;
    background-size: contain !important;
    z-index: 2 !important;
    left: calc(50% - 175px);
    &::before{
      display: none !important;
    }
  }
  .slick-next{
    top: auto !important;
    bottom: -25px !important;
    height: 20px !important;
    width: 83px !important;
    background: url(../img/next_arrow.svg) no-repeat 0 0 !important;
    background-size: contain !important;
    z-index: 2 !important;
    right: calc(50% - 175px) !important;
    &::before{
      display: none !important;
    }
  }
}
@media screen and (max-width: 1279px) {
  .articlesec_main .artcl_slider_inn .slider_text_box h4 {
    font-size: 16px;
    line-height: 28px;
  }
  .articlesec_main .artcl_slider_inn .slider_text_box .flex_box p {
    font-size: 14px;
    line-height: 15px;
  }
  .articlesec_main .artcl_slider_inn .slider_text_box .flex_box a {
    font-size: 14px;
    line-height: 15px;
  }
}
@media screen and (max-width: 767px) {
  .articlesec_main {
    margin-top: 80px;
  }
  .article_main_page {
    padding-top: 100px !important;
  }
}
@media screen and (max-width: 575px) {
  .articlesec_main {
    margin-top: 55px;
  }
  .articlesec_main h6 {
    font-size: 18px;
  }
  .articlesec_main h3 {
    font-size: 36px;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 14px;
}
  .articlesec_main .artcl_slider_inn .slider_text_box h4 {
    font-size: 13px;
    line-height: 20px;
  }
  .articlesec_main .artcl_slider_inn .slider_text_box .flex_box {
    margin-top: 13px;
  }
  .articlesec_main .artcl_slider_inn .slider_text_box .flex_box p {
    font-size: 12px;
  }
  .articlesec_main .artcl_slider_inn .slider_text_box .flex_box a {
    font-size: 13px;
  }
  .articlesec_main .slick-prev {
    bottom: -15px !important;
    height: 15px !important;
    width: 57px !important;
    left: calc(50% - 136px) !important;
  }
  .articlesec_main .slick-next{
    bottom: -15px !important;
    height: 15px !important;
    width: 57px !important;
    right: calc(50% - 136px) !important;
  }
  .articlesec_main .slick-dots {
    bottom: -11px;
  }
}
.roadmapsec_main{
  margin-top: 250px;
  position: relative;
  min-height: 200px;
  border-radius: 90px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1), #1CE8F8) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 90px;
    pointer-events: none;
  }
  .roamap_bg{
    background: url(../img/roadmap_bg.png) no-repeat center;
    background-size: cover;
    max-width: 1195px;
    min-height: 1196px;
    position: absolute;
    top: 281px;
    width: 100%;
    margin: 0 auto;
  }
  .sadow_box{
    padding: 30px 0 35px 0;
    background: #000000;
    box-shadow: 0px 15px 80px rgba(31, 199, 212, 0.2);
    border-radius: 90px;
    h6{
      font-family: $f_primary;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #1CE8F8;
    }
    h3{
      font-family: $f_primary;
      font-weight: 600;
      font-size: 42px;
      margin-top: 14px;
      line-height: 49px;
      text-align: center;
      color: #FFFFFF;
    }
  }
  .roadmap_cntnt{
    max-width: 867px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    .road_cntnt_row{
      display: flex;
      align-items: center;
      margin: 74px 0px !important;
      .road_map_img_prnt{
        display: flex;
        align-items: center;
        justify-content: center;
        
        background: linear-gradient(180deg, rgba(10, 110, 118, 0.6) 0%, rgba(31, 199, 212, 0.6) 100%);
        padding: 5px;
        box-shadow: 0px 15px 80px #1FC7D4;
        border-radius: 20px;
        transform: rotate(45deg);
        .road_map_img_prnt_inn {
          height: 126px !important;
          min-width: 126px !important;
          background: #191D25;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          h4 {
            color: #fff;
            transform: rotate(-45deg);
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 44px;
            text-align: center;
            color: #1CE8F8;

          }
        }
      }
      .road_map_img0 {
        border-radius: 50%;
        box-shadow: 0px 15px 80px rgba(13, 110, 253, 0.6);
        height: 129px;
        width: 129px;
      }
      .road_text_prnt{
        margin-left: 60px;
        h5{
          font-family: $f_primary;
          font-weight: 600;
          font-size: 28px;
          line-height: 33px;
          color: #FFFFFF;
          margin-bottom: 20px;
        }
        p{
          font-family: $f_primary;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }
    }
    .road_cntnt_row_01{
      margin: 74px 0px !important;
    }
  }
  .enter_roadmap_btn_prnt{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 38px !important;
  }
  .enter_roadmap_btn{
    position: relative;
    font-family: $f_primary;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 206px;
    height: 42px;
    text-align: center;
    color: black;
    background: #1CE8F8;
    border-radius: 60px;
    margin: 0 auto !important;
    margin-bottom: -21px !important;
    box-shadow: none !important;
    transition: all 0.2s ease-out;
    &:hover{
      background:#05b9c7 ;
    }
  }
}
@media screen and (max-width: 991px) {
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_map_img_prnt{
    margin-bottom: 54px;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row{
    flex-direction: column;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_text_prnt{
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .roadmapsec_main .sadow_box h6 {
    font-size: 19px;
    line-height: 22px;
  }
  .roadmapsec_main .sadow_box h3 {
    font-size: 38px;
    margin-top: 12px;
    line-height: 44px;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_text_prnt h5 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_text_prnt p {
    font-size: 15px;
    line-height: 22px;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_text_prnt {
    margin-left: 7px;
  }
  .roadmapsec_main {
    margin-top: 140px;
  }
}
@media screen and (max-width: 575px) {
  .roadmapsec_main {
    margin-top: 70px;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row {
    flex-direction: column;
    padding: 0 10px;
    margin-top: 30px;
  }
  .roadmapsec_main .sadow_box h3 {
    font-size: 34px;
    margin-top: 12px;
    line-height: 34px;
  }
  .roadmapsec_main .sadow_box h6 {
    font-size: 18px;
    line-height: 22px;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_text_prnt h5 {
    text-align: center;
  }
  .roadmapsec_main .roadmap_cntnt .road_cntnt_row .road_text_prnt p {
    text-align: center;
  }
  .roadmapsec_main .enter_roadmap_btn {
    font-size: 13px;
    line-height: 16px;
    width: 160px;
    height: 38px;
    margin-bottom: -19px;
  }
}
.howtobuy_main{
  display: flex;
  margin-top: 140px;
  padding: 0 15px;
  .howtobuy_inn{
    
    border-radius: 90px;
    max-width: 1784px;
    min-height: 200px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    .howtobuy_cntnt{
      display: flex;
      background: linear-gradient(155.73deg, #06EBB4 12.66%, #1CE8F8 76.34%);
      align-items: center;
      padding: 40px 30px;
      min-height: 535px;
      .htb_cntnt_box{
        max-width: 468px;
        margin-left: auto;
        margin-right: 105px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        h3{
          font-family: $f_primary;
          font-weight: 600;
          font-size: 44px;
          line-height: 52px;
          color: #000000;
        }
        p{
          font-family: $f_primary;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          margin-top: 25px;
          margin-bottom: 34px;
        }
        a{
          font-family: $f_primary;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          background: #12151A;
          border-radius: 60px;
          width: 247px;
          height: 42px;
          box-shadow: none !important;
          transition: all 0.2s ease-out;
          border:1px solid rgba(28, 232, 248, 1);
          &:hover{
            color: #9fbff0;
          }
        }
      }
    }
    .howtobuy_img{
      img{
        mix-blend-mode: overlay;
        width: 100%;
        min-height: 535px;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .howtobuy_main .howtobuy_inn .howtobuy_img img{
    min-height: inherit !important;
  }
}
@media screen and (max-width: 1399px) {
  .howtobuy_main .howtobuy_inn .howtobuy_img img{
    min-height: 415px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt{
    min-height: 415px;
  }
}


@media screen and (max-width: 1600px) {
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box {
    margin-right: auto;
    margin-top: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box {
    margin-right: auto;
    margin-top: 0px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box h3 {
    font-size: 40px;
    line-height: 52px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box p {
    font-size: 15px;
    line-height: 22px;
    margin-top: 14px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 575px) {
  .howtobuy_main .howtobuy_inn {
    border-radius: 60px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box h3 {
    font-size: 34px;
    line-height: 52px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 3px;
    margin-bottom: 20px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt .htb_cntnt_box a {
    font-size: 13px;
    width: 216px;
    height: 38px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt {
    padding: 40px 10px 24px 10px;
  }
}
.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 25px 0;
  background: #000000;
  h4{
    font-family: $f_primary;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color:#1CE8F8;
  }
  .social_lin_prnt{
    margin-top: 23px;
    display: flex;
    a{
      padding: 0 !important;
      min-width: 0 !important;
      box-shadow: none !important;
      outline: none !important;
      height: auto;
      background: transparent;
      position: relative;
      top: 0;
      transition: all 0.2s ease-out;
      height: 115px;
      width: 115px !important;
      &:hover{
        top: -5px;
      }
    }
  }
  p{
    font-family: $f_primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8C8C8C;
    margin-top: 19px;
  }
}
@media screen and (max-width: 575px) {
  .footer h4 {
    font-size: 27px;
    line-height: 30px;
  }
  .footer .social_lin_prnt a {
    height: 74px;
    width: 74px !important;
  }
  .footer p {
    margin-top: 11px;
  }
}
.sidebar_main{
  background: #000000;
  box-shadow: 0px 15px 80px rgba(31, 199, 212, 0.2);

}
.sidebar_logo_prnt{
  background: linear-gradient(107.64deg, #06EBB4 15.14%, #1CE8F8 88.19%);
  justify-content: center;
  padding: 5px;
}
.dashboard_links_prnt{
  flex-direction: column;
  padding: 20px 20px 10px 20px;
  p{
    font-family: $f_primary;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #989898;
    margin-bottom: 29px;
  }
}
.dashboard_links{
  flex-direction: column;
  padding-left: 14px;
  a{
    font-family: $f_primary;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #FFF;
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    img{
      margin-right: 15px;
      filter: invert(1) brightness(100) ;
    }
    &:hover{
      img{
        filter: invert(0) brightness(100%) ;
      }
      span{
        color: #1CE8F8;
      }
    }
    &.active {
      span{
        color: #1CE8F8;
      }
      img{
        filter: invert(0) brightness(100%) ;
      }
    }
  }
}
.social_links_prnt{
  flex-direction: column;
  padding: 0 20px;
  p{
    font-family: $f_primary;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #989898;
    margin-bottom: 29px;
  }
  .socl_links_dash{
    flex-direction: column;
    padding-left: 14px;
    a{
      font-family: $f_primary;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #fff;
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      text-decoration: none !important;
      box-shadow: none !important;
      transition: all 0.2s ease-out;
      &:focus{
        display: none !important;
      }
      img{
        margin-right: 13px;
        filter: invert(1) brightness(100) ;
      }
      &:hover{
        img{
          filter: invert(0) brightness(100%) ;
        }
        span{
          color: #1CE8F8;
        }
      }
      &.active {
        span{
          color: #1CE8F8;
        }
        img{
          filter: invert(0) brightness(100%) ;
        }
      }
      // &:hover{
      //   img{
      //     filter: invert(54%) sepia(93%) saturate(5742%) hue-rotate(207deg) brightness(96%) contrast(107%);
      //   }
      //   span{
      //     color: #0D6EFD;
      //   }
      // }
    }
  }
}
.sidebar_header{
  padding: 15px 20px;
}
.sidebar_header_btns{
  a{
    width: 189px;
    height: 42px;
    font-family: $f_primary;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background: #000000;
    border: 1px solid #1CE8F8;
    border-radius: 60px;
    transition: all 0.2s ease-out;
    &:hover{
      background: #1CE8F8;
      color: #000 !important;
    }
  }
  .cnct_wallet_btn_v2{
      color: white !important;
      border: 1px solid rgba(28, 232, 248, 1);
      &:hover{
        background:#05b9c7 ;
        color: black !important;
      }
    }
  .cnct_wallet_btn{
    background:#1CE8F8 ;
    margin-left: 15px;
    color: #000;
    &:hover{
      background:#000 ;
      color: #fff !important;
    }
  }
}
.sidebar_close_btn{
  margin-left: auto !important;
  border: 1px solid #ffffff !important;
  svg{
    color: #ffffff !important;
  }
}
.dashbourd_menu_btn{
  border: 1px solid rgba(28, 232, 248, 1) !important;
  svg{
    color: rgba(28, 232, 248, 1) !important;
  }
}
.sidebar_header {
  position: fixed !important;
  margin: 0 !important;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}
// .sidebar_main {
//   overflow-y: scroll;
// }
@media screen and (max-width: 1279px) {
  .sidebar_main {
    width: 300px !important;
  }
  .drawer_slider{
    width: 300px !important;
  }
  .sidebar_logo_prnt {
    img{
      margin-left: 20px;
    }
  }
  .dashboard_links_prnt p {
    margin-bottom: 16px;
  }
  .dashboard_links a {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 28px;
  }
  .social_links_prnt p {
    margin-bottom: 16px;
  }
  .social_links_prnt .socl_links_dash a{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 28px;
  }
}
@media screen and (max-width: 575px) {
  .sidebar_header_btns a {
    width: 122px;
    height: 38px;
    font-size: 11px;
    line-height: 16px;
  }
  .sidebar_header_btns .cnct_wallet_btn{
    margin-left: 5px;
  }
  .sidebar_header {
    padding: 10px 10px;
  }
  .dashboard_links_prnt p{
    margin-bottom: 12px;
    font-size: 13px;
  }
  .dashboard_links a {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .social_links_prnt p {
    font-size: 13px;
    margin-bottom: 12px;
  }
  .social_links_prnt .socl_links_dash a{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
  }
  .dashboard_links_prnt {
    padding: 15px 15px 10px 15px;
  }
  .social_links_prnt {
    padding: 0 15px;
  }
  .sidebar_main {
    width: 250px !important;
  }
  .drawer_slider{
    width: 250px !important;
  }
  .dashboard_links a img {
    margin-right: 10px;
    width: 17px;
  }
  .social_links_prnt .socl_links_dash a img{
    margin-right: 10px;
    width: 17px;
  }
}
.dsbrdanmtnbg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.dsbrdanmtnbg>div {
  width: 100% !important;
  height: 100% !important;
}
.dashboard_main_page{
  background: url(../img/dashboard_bg.jpg) no-repeat -304px 0;
  background-size: cover;
  min-height: 2057px;
  padding: 0 62px 40px 62px;
  position: relative;
  .dashboard_cntnt_box{
    max-width: 1490px;
    padding-top: 80px;
    margin: 0 auto;
    .dashboard_chart_box{
      // background: rgba(0, 0, 0, 0.8);
      min-height: 508px;
      max-width: 729px;
      margin: 0 auto;
      box-shadow: 0px 0px 10px 2px rgba(149, 191, 254, 0.5);
      border-radius: 16px;
      padding: 20px 20px 30px 30px;
      margin-top: 25px;
      position: relative;
      &::before{
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: -1px;
        border: 1px solid transparent;
        background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
        border-radius: 16px;
        pointer-events: none;
      }
      .flex_chart_header {
        display: flex;
        align-items: center;
        h3{
          font-family: $f_primary;
          font-weight: 700;
          font-size: 26px;
          line-height: 30px;
          color: #FFFFFF;
        }
      }
    }
    .dashboard_chart_box_last{
      padding: 0;
      max-width: 100%;
      .flex_chart_header_last{
        padding: 20px 20px 18px 30px;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .dashboard_main_page {
    padding: 0 14px 0px 14px;
  }
}

@media screen and (max-width: 575px) {
  .dashboard_main_page .dashboard_cntnt_box .dashboard_chart_box {
    background: rgba(0, 0, 0, 0.8);
    min-height: 349px;
  }
  .dashboard_main_page .dashboard_cntnt_box .dashboard_chart_box {
    min-height: inherit;
  }
}
.pools_tablemain{
  overflow-x: auto;
  table{
    min-width: 660px;
    thead{
      background: linear-gradient(180deg, #303030 0%, #1B1B1B 100%);
      tr{
        th{
          border: none !important;
          font-family: $f_primary;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: #747D84;
          padding: 10px;
          text-align: center;
          &:last-child{
            padding-right: 50px;
          }
          .tvl_head {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .pool_head{
            display: flex;
            align-items: center;
          }
          .volume_head{
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    tbody{
      tr{
        td, th{
          border-bottom: 0.7272px solid #465062 !important;
          font-family: $f_primary;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #1CE8F8;
          padding: 10px;
          &:last-child{
            padding-right: 50px;
          }
        }
        .volume_column{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .poolcolm_data{
          display: flex;
          align-items: center;
          img{
            margin-right: 8px;
          }
          span{
            width: 49.45px;
            height: 26.18px;
            border-radius: 4.3632px;
            background: #141E28;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
.pagination_row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 25px 0;
  button{
    height: auto !important;
    width: auto;
    padding: 0;
    background: transparent !important;
    box-shadow: none !important;
  }
  p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15.9984px;
    line-height: 19px;
    text-align: center;
    color: #5e656b;
    padding: 0 5px;
    span{
      color: #747D84;
    }
  }
}
@media screen and (max-width: 991px) {
  .pools_tablemain table tbody tr td, .pools_tablemain table tbody tr th {
    font-size: 14px;
    line-height: 15px;
  }
  .pools_tablemain table tbody tr td:last-child, .pools_tablemain table tbody tr th:last-child {
    padding-right: 25px;
  }
  .pools_tablemain table thead tr th:last-child {
    padding-right: 25px;
  }
  .howtobuy_main .howtobuy_inn .howtobuy_cntnt {
    min-height: inherit;
  }
}
.article_main_page{
  padding-top: 200px;
  background: url(../img/artivle_bg.jpg) no-repeat center;
  background-size: cover;
  min-height: 2446px;
  .how_to_buy_Sec_rspv{
    margin: 0 68px;
  }
}
@media screen and (max-width:1199px) {
  .article_main_page .how_to_buy_Sec_rspv {
    margin: 0 45px;
  }
}
@media screen and (max-width:991px) {
  .article_main_page .how_to_buy_Sec_rspv {
    margin: 0 20px;
  }
}
@media screen and (max-width:767px) {
  .article_text_img{
    h3{
      justify-content: center;
    }
  }
  .tweets_box .tweet_main{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width:575px) {
  .article_main_page .how_to_buy_Sec_rspv {
    margin: 0;
  }
  .article_main_page{
    padding-top: 100px;
  }
  .article_text_img h3 {
    font-size: 28px !important;
    line-height: 30px !important;
  }
}
.article_text_img{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  h3{
    font-family: $f_primary;
    font-weight: 600;
    font-size: 46px;
    line-height: 54px;
    text-align: center;
    color: #1CE8F8;
    margin-left: 4px;
  }
}

.article_left_box{
  .article_img_01{
    box-shadow: 0px 20px 40px rgba(13, 110, 253, 0.4);
    border-radius: 12.2656px;
    margin-top: 16px;
  }
  .pagination_img{
    margin: 40px auto 0 auto;
  }
}
.staking_main{
  min-height: 100vh;
  // background: url(../img/staking_bg.jpg) no-repeat right 0;
  background-size: cover;
  padding-top: 98px;
  padding-bottom: 40px;
  position: relative;
}
.tab_btn_prnt{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .tab_border{
    display: flex !important;
    align-items: center !important;
    border-radius: 60px;
    overflow: hidden;

    .staktab01{
      box-shadow: none !important;
      width: 196.5px;
      height: 43px;
      font-family: $f_primary;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
    button+[aria-selected=false]{
      color: #353535 !important;
    }
    .staktab02{
      box-shadow: none !important;
      width: 196.5px;
      height: 43px;
      font-family: $f_primary;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      // &:hover{
      //   color: #000000 !important;
      // }
    }
    button[aria-selected=true]{
      background: linear-gradient(107.64deg, #168E97 15.14%, #034D53 88.19%);
    }
  }
  
}
.stakone_main{
  box-shadow: 0px 0px 10px 2px rgba(149, 191, 254, 0.5);
  border-radius: 16px;
  max-width: 652px;
  min-height: 550px;
  margin: 25px auto 20px auto;
  padding: 30px 0;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 16px;
    pointer-events: none;
  }
  h4{
    font-family: $f_primary;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 25px;
  }
}
.stake_tab_panel01_prnt{
  padding: 0 !important;
}

.stake_tab_panel01{
  margin-top: 35px;
  padding: 25px 40px 40px 40px;
  background: linear-gradient(284.39deg, #093C40 10.36%, #00152D 85.2%);
  .con_w_btn{
    background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%);
    border-radius: 60px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 16.0741px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    transition: all 0.2s ease-out;
    width: 100%;
    margin-top: 22px;
    box-shadow: none !important;
    &:hover{
      background: linear-gradient(284.39deg, #00418C 10.36%, #168E97 85.2%);
    }
  }
  h6{
    font-family: $f_primary;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 12PX;
  }
  .stake_full_btn{
    font-family: $f_primary;
    font-style: normal;
    font-weight: 700;
    font-size: 16.0741px;
    line-height: 19px;
    text-align: center;
    color: #000;
    background: #1CE8F8;
    border-radius: 60px;
    width: 100%;
    height: 48px;
    margin-top: 20px;
    transition: all 0.2s ease-out;
    &:hover{
      background: #1ce9f8b7;
    }
    &:disabled{
      background: #525252;
      color: #fff;
    }
  }
}
.stake_inpt_box{
  border: 1px solid #6D7D96;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 10px;
    pointer-events: none;
  }
  .text_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      font-family: $f_primary;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
    }
  }
  .inpt_btn_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    input{
      font-family: $f_primary;
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      width: 40%;
      color: #FFFFFF;
      background: transparent;
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
    .btn_text_prnt{
      display: flex;
      align-items: center;
      button{
        font-family: $f_primary;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        background: #083B3F;
        border-radius: 4px;
        width: 56px;
        height: 36px;
        transition: all 0.2s ease-out;
        &:hover{
          background: #05b9c7;
        }
      }
      p{
        font-family: $f_primary;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        margin-left: 7px;
      }
    }
  }
}
.stake_emp_dex_btns{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  button{
    width: 182px;
    height: 48px;
    background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%) !important;
    box-sizing: border-box;
    border-radius: 90px;
    font-family: $f_primary;
    font-style: normal;
    font-weight: 700;
    font-size: 16.0741px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    background: transparent;
    transition: all 0.2s ease-out;
    margin: 0 4px;
    box-shadow: none !important;
    &:hover{
      background: linear-gradient(284.39deg, #00418C 10.36%, #168E97 85.2%) !important;
    }
  }
  button[disabled]{
    background: #525252 !important;
  }
}
.stake_emp_dex_btns02{
  button{
    max-width: 297px;
    width: 100%;
    background: linear-gradient(107.64deg, #168E97 15.14%, #034D53 88.19%) !important;
  }
}
.contracts_box{
  max-width: 652px;
  margin: 0 auto;
  padding: 20px 20px 25px 20px;
  background: linear-gradient(0deg, #303030 0%, #1B1B1B 100%);
  border-radius: 16px;
  position: relative;
    h4{
    font-family: $f_primary;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
  }
  .upcro_copyflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    h6{
      display: flex;
      align-items: center;
      font-family: $f_primary;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF; 
      p{
        padding: 10px 12px;
        display: flex;
        align-items: center;
        font-family: $f_primary;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        background: #000;
        border-radius: 8px;
        color: #FFFFFF;
        margin-left: 8px;
        button{
          width: auto;
          min-width: 0;
          height: auto;
          padding: 0;
          background: transparent;
          margin-left: 15px;
          box-shadow: none !important;
          &:hover{
            img{
              filter: invert(0) brightness(50%) ;
            }
          }
        }
      }
    }
    .right_h6{
      margin-left: 20px;
    }
  }
}
@media screen and (max-width: 767px) {
  .contracts_box .upcro_copyflex {
    flex-direction: column;
  }
  .contracts_box .upcro_copyflex .right_h6 {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .tab_btn_prnt .tab_border .staktab01 {
    box-shadow: none !important;
    width: 124px;
    height: 38px;
    font-size: 12px;
    color: #ffffff !important;
  }
  .tab_btn_prnt .tab_border .staktab02 {
    box-shadow: none !important;
    width: 124px;
    height: 38px;
    font-size: 12px;
    color: #ffffff !important;
  }
  .stakone_main {
    padding: 20px 0 30px 0;
    min-height: 0;
  }
  .stakone_main h4 {
    font-size: 23px;
    line-height: 26px;
    margin-bottom: 17px;
  }
  .stake_tab_panel01 {
    margin-top: 25px;
    padding: 20px 10px 25px 10px;
  }

  .stake_tab_panel01 h6 {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8PX;
  }
  .stake_inpt_box {
    padding: 10px;
  }
  .stake_inpt_box .text_row p {
    font-size: 12px;
    line-height: 16px;
  }
  .stake_inpt_box .inpt_btn_row input {
    font-size: 20px;
    line-height: 26px;
  }
  .stake_inpt_box .inpt_btn_row .btn_text_prnt button {
    font-size: 13px;
    line-height: 19px;
    width: 46px;
    height: 26px;
  }
  .stake_inpt_box .inpt_btn_row .btn_text_prnt p {
    font-size: 16px;
    line-height: 20px;
    margin-left: 4px;
  }
  .stake_inpt_box .inpt_btn_row {
    margin-top: 12px;
  }
  .stake_tab_panel01 .stake_full_btn {
    font-size: 15px;
    height: 40px;
    margin-top: 15px;
  }
  .stake_emp_dex_btns {
    margin-top: 5px;
    flex-direction: column;
    padding: 0 10px;
    button{
      height: 42px;
      font-size: 15px;
      margin-top: 10px;
    }
  }
  .contracts_box h4 {
    font-size: 24px;
    line-height: 26px;
  }
  .contracts_box .upcro_copyflex h6 {
    font-size: 16px;
    line-height: 18px;
  }
  .contracts_box .upcro_copyflex h6 p {
    padding: 10px 12px;
    font-size: 15px;
    margin-left: 5px;
  }
  .contracts_box {
    padding: 16px 10px 18px 10px;
  }
  .staking_main {
    padding-top: 84px;
    padding-bottom: 30px;
  }
}
.padding_0{
  padding: 0 !important;
}
.sidebar_header.scrolled{
  background: rgba(0, 0, 0, 0.8);
}
.header.scrolled{
  background: #272d37d8;
}
.swap_main{
  background: url(../img/swap_bg.jpg) no-repeat right 0;
  background-size: cover;
  min-height: 100vh;
  padding-top: 154px;
  padding-bottom: 40px;
  position: relative;
}
.grdint_brder{
  position: relative;
  max-width: 652px;
  margin: 0 auto;
  border-radius: 16px;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 16px;
    pointer-events: none;
  }
}
.swap_center_btn_prnt{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -10px 0;
}
.swap_border_Box{
  box-shadow: 0px 15px 80px rgba(31, 199, 212, 0.2);
  border-radius: 16px;
  max-width: 652px;
  margin: 0 auto;
  background: #000;
  backdrop-filter: blur(10px);
  // overflow: hidden;
  margin-bottom: 40px;
  position: relative;
  .setting_btn{
    position: absolute;
    right: 10px;
    top: 25px;
    margin-left: 0;
  }
    .swap_center_btn{
      position: relative;
      left: 0;
      min-width: 36px;
      height: 36px;
      padding: 0 !important;
      background: #000;
      box-shadow: none !important;
      bottom: 0;
      z-index: 1;
      &:hover{
        background: #599497;
      }
    }
  h4{
    padding: 27px 0 20px 0;
    font-family: $f_primary;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
  }
  .spwa_cntnt_dark_box{
    padding: 35px 40px 25px 40px;
    background: linear-gradient(284.39deg, #093C40 10.36%, #00152D 85.2%);
    border-radius: 0px 0px 16px 16px;
    .con_w_btn{
      background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%);
      border-radius: 60px;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 16.0741px;
      line-height: 19px;
      text-align: center;
      color: #FFFFFF;
      transition: all 0.2s ease-out;
      width: 100%;
      margin-top: 22px;
      border: none !important;
      &:hover{
        background: linear-gradient(284.39deg, #00418C 10.36%, #168E97 85.2%);
      }
    }
  }
  .swap_upcro_brdr_bx{
    padding: 20px 20px 15px 20px;
    border-radius: 10px;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      border: 1px solid transparent;
      background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      border-radius: 10px;
      pointer-events: none;
    }
    .flex_bx{
      display: flex;
      align-items: center;
      justify-content: space-between;
      input{
        outline: none !important;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        font-family: $f_primary;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #FFFFFF;
        width: 40% !important;
      }
      .chakra-select__wrapper  {
        border: 1px solid #BDD7FF !important;
        border-radius: 4px !important;
        width: 114px !important;
        height: 36px !important;
        box-shadow: none !important;
        outline: none !important;
        select{
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
      p{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
    .flex_bx_02{
      margin-top: 24px;
    }
  }
  .swap_btc_brdr_bx{
    margin-top: 0px;
  }
  .text_row_darc{
    display: flex;
    align-items: center;
    margin-top: 18px;
    p{
      font-family: $f_primary;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      margin-right: 15px;
    }
  }
  .stake_full_btn{
    font-family: $f_primary;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: black;
    background: #1CE8F8;
    border-radius: 60px;
    width: 100%;
    height: 48px;
    margin-top: 30px;
    transition: all 0.2s ease-out;
    &:hover{
      background: #05b9c7;
    }
  }
}
.slect_box {
  .bagrund_c{
    background-color: transparent !important;
    border: 1px solid #1CE8F8;
  }
  .slct__menu {
    background: transparent !important;
   
    >div {
      border: 1px solid  #333B49;
      background: #1CE8F8 !important;
      border-radius: 15px;
      div {
      background: #1CE8F8 !important;
        div {
          cursor: pointer;
          &:hover {
            background:#68e7e7 !important ;
          }
          
        }
      }
    }
  }
  .slct__indicator-separator {
    display: none !important;
  }
  .slct__dropdown-indicator{
    svg{
      color: #ffffff !important;
    }
  }
  .slct__value-container.slct__value-container--has-value{
    padding: 0 !important;
  }
  .slct__control {
    background: transparent !important;
    border: 1px solid #1CE8F8 !important;
    border-radius: 4px !important;
    width: 122px !important;
    height: 36px;
    padding-left: 7px;
    display: flex;
    align-items: center;
  }
  .country-option {
    display: flex;
    align-items: center;
    img{margin-right: 3px;}
    span{
      font-family: $f_primary;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
    }
  }
}

@media screen and (max-width: 991px) {
  .swap_main {
    padding-top: 106px;
  }
}
@media screen and (max-width: 767px) {
  .swap_main {
    padding-top: 90px;
  }
  .swap_border_Box .text_row_darc p{
    font-size: 15px;
  }
}
@media screen and (max-width: 464px) {
  .swap_border_Box .swap_center_btn {
    min-width: 30px;
    height: 30px;
    bottom: calc(50% - -6px);
  }
}

@media screen and (max-width: 575px) {
  .swap_main {
    padding-top: 80px;
  }
  .swap_border_Box .swap_center_btn {
    min-width: 30px;
    height: 30px;
    bottom: calc(50% + -6px);
  }
  .swap_border_Box h4 {
    padding: 20px 0 16px 0;
    font-size: 24px;
    line-height: 28px;
  }
  .swap_border_Box .spwa_cntnt_dark_box {
    padding: 25px 10px 20px 10px;
  }
  .swap_border_Box .swap_upcro_brdr_bx {
    padding: 12px 10px 10px 10px;
  }
  .swap_border_Box .swap_upcro_brdr_bx .flex_bx input {
    font-size: 22px;
    line-height: 27px;
  }
  .swap_border_Box .swap_upcro_brdr_bx .flex_bx p {
    font-size: 14px;
    line-height: 16px;
  }
  .swap_border_Box .swap_upcro_brdr_bx .flex_bx_02 {
    margin-top: 17px;
  }
  .swap_border_Box .text_row_darc p {
    font-size: 14px;
    line-height: 21px;
    margin-right: 10px;
  }
  .swap_border_Box .text_row_darc {
    display: flex;
    align-items: center;
    margin-top: 9px;
    flex-flow: wrap;
  }
  .swap_border_Box .stake_full_btn {
    font-size: 15px ;
    line-height: 19px;
    height: 40px;
    margin-top: 18px;
  }
  .swap_border_Box {
    margin-bottom: 24px;
  }
} 
.faq_main{
  min-height: 1824px;
  background: url(../img/faq_bg.jpg) no-repeat center;
  background-size: cover;
  padding-top: 98px;
  padding-bottom: 40px;
  background-attachment: fixed;
  position: relative;
  .faq_prnt{
    max-width: 1150px;
    margin: 0 auto;
    position: relative;
    h3{
      font-family: $f_primary;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
  }
}
.faq_accordians_prnt{
  padding-left: 39px;
  position: relative;
  .main_accordian{
    max-width: 1142px !important;
    margin: 0 auto !important;
    .boedrd_faq{
      border: 1px solid #1CE8F8 !important;
     box-shadow: 0px 0px 20px 2px rgba(243, 186, 47, 0.4) !important;
      border-radius: 0px 60px 60px 0px !important;
      background: rgba(22, 142, 151, 0.15) !important;
      margin-bottom: 18px;
      &>h2>button{
        box-shadow: none !important;
        padding: 20px 20px 20px 0;
        svg{
          height: 70px;
          width: 70px;
          border-radius: 50%;
          background-color: #000000;
          background: url(../img/opn_img.png) no-repeat center;
          background-size: contain;
          margin-left: 10px;
          &.css-j2ph2z{
            background: url(../img/close_acrdin_ic.svg) no-repeat center;
            transform: rotate(0deg) !important;
            background-size: contain;
          }
          path{
            display: none !important;
          }
        }
        &>div{
          display: flex;
          align-items: center;
          font-family: $f_primary;
          font-weight: 600;
          font-size: 26px;
          line-height: 30px;
          color: #FFFFFF;
          img{
            height: 78px;
            width: 78px;
            border-radius: 50%;
            margin-left: -39px;
            margin-right: 34px;
          }
        }
      }
      .chakra-collapse>div{
        font-family: $f_primary;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #FFFFFF;
        max-width: 933px;
        padding-left: 70px;
        padding-bottom: 35px;
        padding-top: 3px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button > div img {
    height: 60px;
    width: 60px;
    margin-left: -33px;
    margin-right: 11px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button > div {
    font-size: 21px;
    line-height: 26px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button svg {
    height: 45px;
    width: 45px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button {
    padding: 10px 17px 10px 0;
  }
  .faq_accordians_prnt {
    padding-left: 28px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq .chakra-collapse > div {
    font-size: 16px;
    line-height: 24px;
    padding-left: 41px;
    padding-bottom: 23px;
    padding-top: 3px;
  }
}
@media screen and (max-width: 575px) {
  .faq_main .faq_prnt h3 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 18px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button > div img {
    height: 45px;
    width: 45px;
    margin-left: -25px;
    margin-right: 11px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button > div {
    font-size: 15px;
    line-height: 20px;
  }
  .faq_accordians_prnt {
    padding-left: 23px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button svg {
    height: 38px;
    width: 38px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq > h2 > button {
    padding: 10px 12px 10px 0;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq .chakra-collapse > div {
    font-size: 13px;
    line-height: 18px;
    padding-left: 32px;
    padding-bottom: 16px;
    padding-right: 10px;
    padding-top: 0px;
  }
  .faq_accordians_prnt .main_accordian .boedrd_faq {
    border-radius: 0px 35px 35px 0px !important;
    margin-bottom: 13px;
  }
  .faq_main{
    min-height: 1824px;
    background: url(../img/faq_bg.jpg) no-repeat center;
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 20px;
  }
}
.roadmap_main{
  background: url(../img/roadmap_bg.jpg) no-repeat right 0;
  background-size:  cover;
  min-height: 2658px;
  padding-top: 98px;
  h3{
    font-family: $f_primary;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
  }
}
.radmp_cntnt_prnt{
  max-width: 910px;
  margin: 0 auto;
  margin-top: 40px;
}
.ready_text_prnt{
  display: flex;
  width: 215px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  h4{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 44px;
    border: 2px solid #1CE8F8;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    background-color: #000000 !important;
    &::before{
      position: absolute;
      content: "";
      bottom: -472px;
      left: 50%;
      width: 2px;
      height: 472px;
      background: #1CE8F8 !important;
    }
  }
}
.ready_cntnt_inn{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 40px;
  z-index: 1;
  .number_bx{
    display: flex;
    width: 215px;
    align-items: center;
    justify-content: center;
    min-height: 166px;
    h2{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 117px;
      width: 117px;
      // border-radius: 50%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 44px;
      line-height: 52px;
      text-align: center;
      color: #FFFFFF;
      background: #000000;
      border: 2px solid #1CE8F8 !important;

box-sizing: border-box;
border-radius: 20px;
transform: rotate(45deg);
span{
  transform: rotate(-45deg);
}
    }
  }
  .vaulavtin{
    min-height: 166px;
    display: flex;
    align-items: center;
    flex-direction: column;
    h5{
      padding-top: 25px;
      font-family: $f_primary;
      font-weight: 600;
      font-size: 28px;
      line-height: 33px;
      color: #FFFFFF;
      margin-bottom: 15px;
      width: 100%;
    }
    p{
      width: 100%;
      font-family: $f_primary;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
    ul{
      padding-left: 14px;
      width: 100%;
      li{
        font-family: $f_primary;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
      }
    }
  }
}
.roadmp_devlop_box{
  margin-top: 55px;
  margin-bottom: 55px;
  .ready_text_prnt h4{
    width: 215px;
    border: 2px solid #1CE8F8;
    &::before{
      background: #0D6EFD;
    }
  }
  .ready_cntnt_inn .number_bx h2{
    border: 2px solid #0D6EFD;
  }
}
.roadmp_future_box{
  .ready_text_prnt h4{
    width: 215px;
    border: 2px solid #FF8E3C;
    &::before{
      background: #FF8E3C !important;
      height: 714px;
      bottom: -714px;
    }
  }
  .ready_cntnt_inn .number_bx h2{
    border: 2px solid #FF8E3C !important;
  }
}
@media screen and (max-width: 767px) {
  .ready_text_prnt {
    width: 130px;
    margin-bottom: 10px;
  }
  @media screen and (max-width:575px) {
    
  }
  .ready_text_prnt h4 {
    width: 110px;
    height: 36px;
    font-size: 16px;
    line-height: 20px;
  }
  .ready_cntnt_inn .number_bx {
    width: 130px;
    min-width: 130px;
    min-height: 140px;
  }
  .ready_cntnt_inn .number_bx h2 {
    height: 70px;
    width: 70px;
    font-size: 28px;
    line-height: 42px;
  }
  .ready_cntnt_inn {
    margin-bottom: 8px;
  }
  .ready_cntnt_inn .vaulavtin h5 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .roadmp_devlop_box .ready_text_prnt h4 {
    width: 130px;
    font-size: 15px;
  }
  .ready_cntnt_inn .vaulavtin ul li, .ready_cntnt_inn .vaulavtin ul p {
    font-size: 14px;
    line-height: 20px;
  }
  .roadmp_future_box .ready_text_prnt h4::before {
    background: #FF8E3C;
    height: 611px;
    bottom: -611px;
  }
  .roadmap_main {
    padding-bottom: 100px;
    min-height: 0;
  }
}
@media screen and (max-width: 575px) {
  .ready_cntnt_inn .vaulavtin h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .ready_cntnt_inn .vaulavtin ul li, .ready_cntnt_inn .vaulavtin p {
    font-size: 12px;
    line-height: 18px;
  }
  .roadmp_future_box .ready_text_prnt h4 {
    width: 130px;
    font-size: 15px;
  }
  .ready_cntnt_inn .vaulavtin {
    margin-left: -20px;
    min-height: 140px;
  }
  .roadmp_devlop_box {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ready_text_prnt h4::before {
    bottom: -401px;
    height: 401px;
  }
  .roadmap_main h3 {
    font-size: 30px;
    line-height: 36px;
  }
  .radmp_cntnt_prnt {
    margin-top: 26px;
  }
  .roadmap_main {
    min-height: 0;
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .number_bx_last h2{
    position: relative;
    &::before{
      position: absolute;
      content: "";
      top: -82px;
      left: 50%;
      width: 2px;
      height: 82px;
      // background: #FF8E3C;
    }
  }
  .roadmp_future_box .ready_text_prnt h4::before {
    height: 546px;
    bottom: -547px;
  }
  .ready_text_prnt {
    width: 130px;
    margin-bottom: 0;
  }
}
.account_p{
  color: #fff !important;
}
.swap_header_ash{
  padding: 0 !important;
}
div.select_tocn_modal {
  background: #000000;
  border-radius: 7.4264px;
  padding: 5px 30px 2px 30px;
  position: relative;
  margin: 2px;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 7.4264px;
    pointer-events: none;
  }
  .select_text_prnt {
    position: relative;
    background: transparent;
    justify-content: center;
    .close_btn {
      position: absolute;
      z-index: 1;
      right: -15px;
      top: 12px;
      color: #ffffff;

    }
    .select_text {
      font-family: $f_primary;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #FFFFFF;
      padding: 15px 0 14px 0;
    }
  }
  .select_tocn_modal_inn {
    background: transparent;
    padding: 0;
  }
  .inpt_prnt_prnt {
    padding: 17px 30px 4px 30px;
    background: linear-gradient(284.39deg, #093C40 10.36%, #00152D 85.2%);
    border-radius: 7.4264px 7.4264px 0 0;
    input {
      background: #000000;
      border: 0.663466px solid #1CE8F8;
      box-sizing: border-box;
      border-radius: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF; 
      height: 45px;
    }
  }
  .currency_list_prnt>div {
    background: linear-gradient(284.39deg, #093C40 10.36%, #00152D 85.2%);
    border-radius: 7.4264px;
    padding-bottom: 20px;
    .tocn_list_inns {
      padding: 0 50px;
      .currency_text_big {
        font-family: $f_primary;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
      }
      .currency_text_small {
        font-family: $f_primary;
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        color: #FFFFFF;
      }
      .inpt_text_ballnce {
        font-family: $f_primary;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        color: #FFFFFF;
      }
    }
  }
  .currency_list_prnt {
    border: none;
    min-height: 380px;
  }
  .currency_list_prnt>div{
    margin-top: -1px;
    &::-webkit-scrollbar {
      width: 6px;
      border: none;
    }
    
    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #1CE8F8;
      border-radius: 3px;
    }
  }
  .manage_btn_prnt {
    height: 73px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    .manage_btn {
      background: #1CE8F8;
      border: 1px solid #1CE8F8;
      border-radius: 60px;
      width: 189px;
      height: 42px;
      margin: 0 auto;
      font-family: $f_primary;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #353535;
      svg {
        stroke: #353535;
      }
    }
  }
}
.trasaction_stting_modal {
  position: relative;
  min-width: 451px !important;
  height: fit-content;
  width: 100%;
  background: #000000 !important;
  border-radius: 8px;
  // right: calc(50% + 50px);
  padding: 15px 40px 30px 40px;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 8px;
    pointer-events: none;
  }
  .close_btn {
    position: absolute;
    right: 14px;
    min-width: 20px;
    height: 20px;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 11px;
      width: 11px;
    }
  }
  .transctn_header {
    font-family: $f_primary;  
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
  }
  .slippage_p_prnt {
    justify-content: center;
    width: 100%;
  }
  .slippage_p {
    font-family: $f_primary;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.4;
    margin-bottom: 10px;
  }
  .checkbox_options_prnt {
    margin-bottom: 10px;
    button {
      background: #083B3F;
      border-radius: 8px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18.577px;
      line-height: 22px;
      text-align: center;
      color: rgba(255, 255, 255, 0.4);
      width: 68px;
      height: 33px;
      border: none !important;
    }
    button.cHxFfV, button:focus {
      color: #ffffff !important;
      font-weight: 600;
    }
    .index_btn {
      max-width: 121px;
      height: 33px;
        input {
          width: 100%;
          background: transparent;
          font-family: $f_primary;
          font-weight: 400;
          font-size: 18.577px;
          line-height: 22px;
          text-align: right;
          color: #FFFFFF;
          &::placeholder {
            color: #ffffff;
          }
        }
    }
  }
  .deadlin_time_box_prnt {
    width: 145px;
    height: 33px;
    margin: 0 auto;
    background: #083B3F;
    border-radius: 8px;
    padding-right: 9px;
    padding: 5px 10px 5px 5px;
    .deadlin_time_box {
      background: transparent;
      border: none;
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 0;
      input {
        background: transparent;
        border: none;
      }
    }
  }
  .intrfc_text {
    font-family: $f_primary;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
    margin-top: 8px;
  }
  .switch_box_prnt {
    justify-content: center;
    .mode_text {
      font-family: $f_primary;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      color: #1CE8F8;
      min-width: 172px;
    }
    #toggle-expert-mode-button {

    }
  }
}
.switch_raw {
  display: flex;
  align-items: center;
  font-family: $f_primary;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #FFFFFF;
  button{
    margin: 0 5px 0 10px;
    height: 23px;
    width: 45px;
    background: linear-gradient(107.86deg, #00FFC2 9.08%, #00ECFF 117.11%);
    border-radius: 12.75px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 17.85px;
      height: 17.85px;
      border-radius: 50%;
      &.biUDxs, &.iWuFYM {
        background: #ffffff;
      }
      &.sc-hgZZql{
      }
    }
  }
}
.overlay_box {
  position: fixed;
  top: 17px;
  left: 82%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  padding: 0px 10px;
}
@media screen and (max-width: 699px) {
  .swap_border_Box .swap_header_ash .trasaction_stting_modal {
    padding: 8px 20px 20px 20px;
  }
  .overlay_box{
    left: 88%;
  }
}
@media screen and (max-width: 575px) {
  .swap_border_Box .swap_header_ash .trasaction_stting_modal {
    padding: 8px 20px 20px 20px;
    right: 0;
  }
  .swap_border_Box .swap_header_ash {
    padding: 0;
  }
  .overlay_box {
    left: 92%;
  }
  .trasaction_stting_modal {
    min-width: 354px !important;
  }
  div.select_tocn_modal .select_text_prnt .close_btn {
    right: 1px;
  }
  div.select_tocn_modal{
    padding: 5px;
  }
}
@media screen and (max-width: 470px) {
  .swap_border_Box .swap_header_ash .trasaction_stting_modal {
    padding: 5px 8px 13px 8px;
  }
  .trasaction_stting_modal {
    min-width: 281px !important;
  }
  
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .transctn_header {
    font-size: 22px;
    line-height: 26px;
  }
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .slippage_p {
    font-size: 16px;
    line-height: 19px;
  }
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .checkbox_options_prnt button {
    font-size: 15px;
    line-height: 20px;
    width: 50px;
    height: 29px;
  }
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .checkbox_options_prnt .index_btn {
    max-width: 80px;
    height: 30px;
  }
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .checkbox_options_prnt {
    margin-bottom: 5px;
  }
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .intrfc_text {
    font-size: 16px;
    line-height: 20px;
    margin-top: 3px;
  }
  .swap_border_Box .swap_header_ash .trasaction_stting_modal .switch_box_prnt .mode_text {
    font-size: 16px;
    line-height: 21px;
    min-width: 155px;
  }
  .switch_raw {
    font-weight: 300;
    font-size: 15px;
  }
  .switch_raw button {
    margin: 0 3px 0 6px;
    height: 21px;
    width: 42px;
  }
  .switch_raw button span {
    width: 16px;
    height: 16px;
    padding: 0 9px;
  }
}
.max_btn{
  height: 35px !important;
  width: 45px;
  position: relative;
  background: transparent !important;
  color:  #fff !important;
  &:hover{
    border: none !important;
  }
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 4px;
    pointer-events: none;
  }
}
.tex_wit{
  color: #fff !important;
}
.enter_amut_btn{
  background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%);
  border-radius: 60px !important;
  font-family: $f_primary !important;
  font-weight: 700 !important;
  font-size: 16.0741px !important;
  line-height: 19px !important;
  text-align: center !important;
  color: #FFFFFF !important;
  border: none !important;
  &:hover{
    background: linear-gradient(284.39deg, #00418C 10.36%, #168E97 85.2%);
  }
}
.manage_modal_prnt {
  background: #000000;
  border: 1px solid;
  border-radius: 7.4264px;
  position: relative;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 7.4264px;
    pointer-events: none;
  }
  .manage_header_prnt {
    border: none;
    padding: 20px 8px 14px 15px;
    .back_arrow_btn {
      position: relative;
      top: -10px;
      color: #ffffff;
      
    }
    .close_btn {
      position: relative;
      top: -10px;
      color: #ffffff;
    }
    .manage_header {
      font-family: $f_primary;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #FFFFFF;
    }
  }
  .paddin_box {
    padding: 5px 30px;
  }
  .emp_list_prnt {
    background: linear-gradient(284.39deg, #093C40 10.36%, #00152D 85.2%);
    height: 400px;
    border-radius: 7.4264px;
    .input_prnt {
      padding: 15px 28px 2px 28px;
      .inpt_inn {
        input {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          color: rgba(255, 255, 255, 0.4);
          background: #000000;
          border: 0.663466px solid #1CE8F8;
          border-radius: 8px;
        }
      }
    }
  }  
  .manage_list_prnt{
    padding: 24px 20px 0 20px;
    
    &>div>div{
      border-radius: 4px;
      height: 60px;
      &.cGwKhz {
        background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%);
      }
      &>img{
        margin-right: 5px !important;
      }
      .flex_raw {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $f_primary;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        margin-left: auto;
        
        &>button{
          margin: 0 5px 0 5px;
          height: 23px;
          width: 45px;
          background: #000000;
          border-radius: 12.75px;
          padding: 2px 3px;
          div{
            color: transparent;
          }
          span {
            width: 17.85px;
            height: 17.85px;
            border-radius: 50%;
            padding: 9px;
            &.fnRJss {
              background: #fff;
            }
          }
        }
      }
      
    }
  }
}
@media screen and (max-width: 499px) {
  .manage_modal_prnt .paddin_box {
    padding: 2px 15px;
  }
  .manage_modal_prnt .emp_list_prnt .input_prnt {
    padding: 15px 5px 2px 5px;
  }
  .manage_modal_prnt .manage_list_prnt {
    padding: 20px 5px 0 5px;
  }
  .manage_modal_prnt .manage_list_prnt>div>div {
    padding: 10px 7px;
  }
  .manage_modal_prnt .manage_list_prnt > div > div > img {
    margin-right: 4px !important;
    height: 35px;
    width: 35px;
  }
  .manage_modal_prnt .manage_list_prnt > div > div .flex_raw {
    font-size: 11px;
    line-height: 16px;
  }
  .manage_modal_prnt .manage_list_prnt > div > div .flex_raw > button {
    margin: 0 3px 0 3px;
    height: 21px;
    width: 41px;
  }
  .manage_modal_prnt .manage_tocn_text_big {
    font-size: 12px;
    line-height: 16px;
  }
}
button.modal_slct_tcn_wrpr {
  background: transparent;
  padding: 0;
  &>img{
    margin-right: 5px !important;
  }
  &:hover{
    background: transparent;
  }
  &:focus {
    box-shadow: none;
  }
}
.def_modal{
  padding: 5px;
}
section.select_tocan_popup {
  min-height: 603px;
  max-width: 451px;
  background: #000000;
  border: 1px solid;
  position: relative;
  border-radius: 7.4264px;
  &::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -1px;
    border: 1px solid transparent;
    background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    border-radius: 7.4264px;
    pointer-events: none;
  }
  @media screen and (min-width: 1280px) {
    right: -7.5%;
  }
  .chakra-modal__close-btn {
    box-shadow: none;
    svg {
      color: #ffffff;
    }
  }
  .chakra-modal__header {
    padding: 20px 0 14px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
  }
  .containt_cntr {
    padding: 2px 30px;
    .select_tocan_cntnt {
      padding: 17px 30px;
      background: linear-gradient(284.39deg, #093C40 10.36%, #00152D 85.2%);
      border-radius: 7.4264px;
      min-height: 467px;
      .inpt_slect_prnt {
        background: #000000;
        border: 0.663466px solid #1CE8F8;
        border-radius: 8px; 
        height: 45px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        input {
          background: transparent;
          outline: none;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          ::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
      .select_tocan_root_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 274px;
        width: 100%;
        margin: 20px auto;
        height: 33px;
        background: transparent;
        &:focus {
          box-shadow: none;
        }
        h6 {
          font-family: 'Roboto';
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          margin-bottom: auto;
        }
        .root_img_text_box {
          img {
            margin-right: 5px;
          }
          display: flex;
          align-items: center;
          p{
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 11px;
            line-height: 13px;
            color: #FFFFFF;
            margin-top: 5px;
          }
        }
      }
      .exclim_text {
        height: 34px;
        display: flex;
        align-items: center;
        background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%);
        border-radius: 4px;
        padding: 8px 12px;
        justify-content: space-between;
        h5 {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: #FFFFFF;
          margin-right: 10px;
        }
      }
    }
    .mnage_btn_slt {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px auto 16px auto;
      width: 189px;
      height: 42px;
      background: #1CE8F8;
      box-sizing: border-box;
      border-radius: 60px;
    }
  }
  .mnage_btn_slt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px auto 16px auto;
    width: 189px;
    height: 42px;
    background: #1CE8F8;
    box-sizing: border-box;
    border-radius: 60px;
    img {
      margin-right: 3px;
    }
    &:hover{
      background: #1ce8f8c7;
    }
    &:focus{
      box-shadow: none;
    }
  }
}
.chakra-modal__content-container{
  padding: 5px;
}
@media screen and (max-width:460px) {
  section.select_tocan_popup .containt_cntr {
    padding: 2px 15px;
  }
  section.select_tocan_popup .containt_cntr .select_tocan_cntnt {
    padding: 12px 10px;
  }
}
.imp_tocan_popup{
  .root_bnb_box {
    padding: 10px 18px;
    background: linear-gradient(284.39deg, #168E97 10.36%, #00418C 85.2%);
    border-radius: 4px;
    .root_bnb_raw {
      display: flex;
      align-items: center;
      h6{
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        margin: 0 5px;
      }
      p{
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 11px;
        line-height: 13px;
        color: #FFFFFF;
      }
    }
    .alt_text {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #FFFFFF;
      margin-top: 8px;
      margin-bottom: 10px;
    }
    .unknwon_bx {
      background: #000000;
      border-radius: 2.94737px;width: 98px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 4px;
      }
      p {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 8.10526px;
        line-height: 9px;
        color: #FFFFFF;
      }
    }
  }
  .trade_at_your_risk_box {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #FF6C6C;
      margin-top: 15px;
    }
    p{
      font-family: 'Roboto';
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FF6C6C;
      margin: 18px auto 8px auto;
      max-width: 282px;
    }
    h6 {
      font-family: 'Roboto';
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #FF6C6C;
      max-width: 297px;
      margin: 0 auto;
    }
    .undersan_check_box {
      margin-top: 28px;
      .chakra-checkbox__control {
        background: #ffffff;
        box-shadow: none;
        border: none;
        height: 14px;
        width: 14px;
        svg {
          color: #2B2108;
        }
      }
      .chakra-checkbox__label {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #FF6C6C;
      }
    }
  }
  .manage_btn_prnt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0 16px 0;
    .imp_ntn_prnt {
      width: 189px;
      height: 42px;
      background: #1CE8F8;
      border-radius: 60px;
      font-family: 'Roboto';
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #353535;
      &:hover {
        background: #1ce8f8c7;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  // .back_btn_img {
  //   position: absolute;
  //   left: 8px;
  //   background: url(../img/back_arrow_img_bg.svg) no-repeat center;
  //   svg {
  //     display: none;
  //   }
  //   &:hover {
  //     background: url(../img/back_arrow_img_bg.svg) no-repeat center;
  //   }
  // }
}
@media screen and (max-width:460px) {
  .imp_tocan_popup .trade_at_your_risk_box {
    margin-top: 40px;
  }
}
@media screen and (min-width:1280px) {
  .def_modal {
    right: -15% !important;
  } 
}
.pools_main{
  // background: url(../img/pool_page_bg.jpeg) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  padding: 84px 15px 40px 15px;
  position: relative;
  .pool_list_box{
    max-width: 1490px;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    border: 1px solid;
    box-shadow: 0px 0px 30px 2px rgba(243, 186, 47, 0.1);
    position: relative;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: -1px;
      border: 1px solid transparent;
      background: linear-gradient(131deg, #00FFC2, #00ECFF) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
      border-radius: 16px;
      pointer-events: none;
    }
    .flex_chart_header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      h3{
        font-family: $f_primary;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        color: #FFFFFF;
        min-width: 200px;
      }
      .search_bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 547.93px;
        height: 52px;
        width: 100%;
        background: #171717;
        border-radius: 60px;
        padding: 0 12px 0 20px;
        input{
          font-family: $f_primary;
          font-weight: 300;
          font-size: 15.4075px;
          line-height: 18px;
          color: #FFFFFF;
          background: transparent;
          box-shadow: none;
          border: none;
          outline: none;
          width: 80%;
        }
        button{
          padding: 0 5px 0 0;
          height: auto;
          min-width: 0;
          background: transparent;
          &:focus{
            box-shadow: none;
          }
        }
      }
    }
  }
  .pagination_row {
    background: linear-gradient(180deg, #303030 0%, #1B1B1B 100%);
    border-radius: 0px 0px 16px 16px;
  }
}
  @media screen and (max-width: 575px) {
    .pools_main .pool_list_box .flex_chart_header{
      flex-direction: column;
      padding: 10px 15px;
    }
    .pools_main .pool_list_box .flex_chart_header h3 {
      font-size: 22px;
      line-height: 34px;
      min-width: 0;
    }
    .pools_main .pool_list_box .flex_chart_header .search_bar {
      height: 40px;
    }
  }
.desbor_b_remov{
  box-shadow: none !important;
  .pool_list_box{
    border: none;
    &::before{
      display: none;
    }
  }
  &::before{
    display: none;
  }
  .pools_main{
    padding: 0;
    .dsbrdanmtnbg{
      display: none;
    }
  }
}
.btn_text_prnt{
  .max_btn{
    background: #083B3F !important;
    border: none !important;
    color: #fff !important;
  }
}
.glow-on-hover {
  // width: 220px;
  // height: 50px;
  border: none;
  outline: none;
  // background: #000;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 60px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #00ffaa, #33ff00, #00ffc8, #48ff00, #00ffd5, #00fff28f, #0099ff, #008cff, #00a2ff);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 60px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after, .glow-on-hover:hover:after {
  background: #000;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05b9c7;
  left: 0;
  top: 0;
  border-radius: 60px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}



.glow-on-hover-v2 {
  // width: 220px;
  // height: 50px;
  border: none;
  outline: none;
  
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 60px;
}

.glow-on-hover-v2:before {
  content: '';
  background: linear-gradient(45deg, #00ffaa, #33ff00, #00ffc8, #48ff00, #00ffd5, #00fff28f, #0099ff, #008cff, #00a2ff);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 60px;
}

.glow-on-hover-v2:active {
  color: #000
}

.glow-on-hover-v2:active:after {
  background: transparent;
}

.glow-on-hover-v2:hover:before {
  opacity: 1;
}

.glow-on-hover-v2:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  left: 0;
  top: 0;
  border-radius: 60px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}